.spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #111111;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}